import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Info from '../components/Info';
import Entreprise from '../components/Entreprise';
import { homeObjOne } from '../components/Entreprise/Data';
import { homeObjFoor } from '../components/Info/Data';
import Offres from "../components/Offres";
import Services from "../components/Services";
import Partenaires from "../components/Partenaires";
import Ressources from "../components/Ressources";
import Commercial from "../components/Commercial";
import ContactForm from "../components/ContactForm/contact";
import Testimonial from '../components/Testimonial';


function Home() {

  return (
    <div>
      <Navbar/>
      <Hero />
      <Entreprise {...homeObjOne} />
      <Offres />
      <Services />
      <Partenaires />
      <Ressources />
      <Commercial />
      <Testimonial/>
      <Info {...homeObjFoor} />
      <ContactForm/>
    </div>
  );
}

export default Home;
