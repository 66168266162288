import React from "react";

import { Link } from "react-router-dom";
import { //MDBCol,
     MDBContainer,
      //MDBRow
      } from 'mdb-react-ui-kit';

const VersAccueil = () => {
    return (
      <div  style={{ backgroundColor: '#2196F3', paddingTop: "30px", paddingBottom: "30px", color: "white" }} data-aos='fade-down'  data-aos-delay="100" >
      <MDBContainer>
      <Link style={{ color: "white" ,  textDecoration: "none" }} to="/">Retour à la page d'accueil</Link>
      </MDBContainer>
  </div>
    );
  };

  export default VersAccueil;