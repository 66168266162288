import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import Flag from 'react-world-flags';
//import { Link } from "react-router-dom";


//import Cloche from "../ClocheEvent";
//import { MDBIcon } from 'mdb-react-ui-kit';

import Icon1 from '../../assets/images/logo-navbar-white.png'
//import IconFR from '../../assets/images/fr.png'
//import IconEN from '../../assets/images/en.png'
import {BiMenuAltRight, BiX} from 'react-icons/bi'
import {IconContext} from 'react-icons/lib'
import {animateScroll as scroll} from 'react-scroll'
//import { Modal} from 'react-bootstrap'
import 'mdbreact/dist/css/mdb.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
//import styled from 'styled-components';


import {
  Nav,
  NavContainer,
  NavLogo,
  NavIcon,
  NavWrapper,
  NavMenu,
  NavItem,
  NavLink,
  //NavBtn,
  //NavBtnLink,
  NavbarIcon
} from './NavbarStyles'
//import Example from '../OffCanvass';


const Navbar = () => {
  
  const [click, setClick] = useState(false);
  const handleClick = ()=> setClick(!click);
  const closeMenu = ()=> setClick(false);

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = ()=>{
    if(window.scrollY >= 80) {
      setScrollNav(true);
    }else{
      setScrollNav(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toogleTop = () => {
    scroll.scrollToTop();
  }

  
      
    
      

      // languages functions
      
      function switchLanguage(lang) {
        i18n.changeLanguage(lang);
      }

      const [isFr, setIsFr] = useState(true);
      
      
      function handleClickLang() {
        switchLanguage(isFr? 'en':'fr');
        setIsFr(!isFr);
      }
  

  const { t } = useTranslation();

  return (
    <div  >
      <IconContext.Provider value={{color: '#fff'}}>
      <Nav scrollNav={scrollNav} >
        <NavContainer data-aos='fade-down'  data-aos-delay="100" >

          <NavLogo to="/" onClick={toogleTop}><NavbarIcon src={Icon1} alt='Layer Data'/></NavLogo>

          <NavIcon onClick={handleClick}>
            {click ? <BiX/> : <BiMenuAltRight/> }
          </NavIcon>
          
            <NavMenu onClick={handleClick} click={click}>
              <NavWrapper>
                
                <NavItem>
                  <NavLink 
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  
                  onClick={closeMenu} to="about">{t('menu.entreprise')}</NavLink>
                </NavItem>

                <NavItem>
                  <NavLink 
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  
                  onClick={closeMenu} to="vision">{t('menu.vision')}</NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  onClick={closeMenu} to="offres">{t('menu.offres')}</NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                   onClick={closeMenu} to="services">{t('menu.services')}</NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-90}
                   onClick={closeMenu} to="partenaires">{t('menu.partenaires')}</NavLink>
                </NavItem>
                
                <NavItem>
                  <NavLink
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                   onClick={closeMenu} to="ressources">{t('menu.experts')}</NavLink>
                </NavItem>

                <NavItem > 
                  <NavLink  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  onClick={closeMenu} to="Contact">{t('menu.nous-contacter')}</NavLink>
                </NavItem>
{/*
                <NavItem > 
                  <a  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  onClick={closeMenu} 
                  href="/home#Contact">
                    {t('menu.nous-contacter')}
                  </a>
                </NavItem>
 
                <NavItem  >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }} >
                <Flag code={isFr ? "USA" : "FRA" } height="20" onClick={handleClickLang} style={{  cursor: 'pointer'  }} />
                
                </div>

                


                </NavItem>*/}

                


              </NavWrapper>

                
            </NavMenu>
    
        </NavContainer>

        <div data-aos='fade-down-left'  data-aos-delay="1000" style={{ position: 'fixed', top: '20%', right: '0', transform: 'translateY(-50%)', backgroundColor: '#1FA8FF', padding : '10px', borderRadius : '10px 0 0 10px' }} >
         <Flag code={isFr ? "GBR" : "FRA" } height="20" onClick={handleClickLang} style={{  cursor: 'pointer'  }} />
        </div>
       {/**  <div data-aos='fade-down-right'  data-aos-delay="4000" style={{ position: 'fixed', top: '30%', left: '0', transform: 'translateY(-50%)', backgroundColor: '#1FA8FF', padding : '10px', borderRadius : '0 10px 10px 0' }} >
      
        <Link  style={{ color: "white" ,  textDecoration: "none" }}
        smooth={true} duration={500} spy={true} exact='true' offset={-80} to="/InscriptionEvent" >

                   Afterwork : 4 juillet : Generator Hostel
                    
                   
                   Afterwork Data Governance: Layer Data / Maisons du monde / DataGalaxy <br/>
                    4 juillet à partir de 18H, au Generator Hostel Paris 10e

                    <Cloche content="
              
                    "
                    /> 
        </Link> 

        
        </div>*/}
      </Nav>
    
      </IconContext.Provider>
      
      
      
      
      
    </div>
  )
};

export default Navbar;
