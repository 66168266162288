import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './en/en.json';
import translationFR from './fr/fr.json';

// les traductions disponibles pour votre application
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};

// initialise la configuration
i18n.use(initReactI18next).init({
    resources,
    lng: 'fr', // la langue initiale de l'application
    fallbackLng: 'fr', // langue de secours en cas de problème de traduction
    interpolation: {
      escapeValue: false // empêche l'encodage de caractères spéciaux
    }
  });

export default i18n;
