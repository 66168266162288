
import {createGlobalStyle} from 'styled-components';
//////////////////////////////////////////////////////////////////
//////////////****AUTHOR : AGHILES AZZOUG -  *////////////////////
//////////////*****JUIN - AOUT 2021 */////////////////////////////
/////////////Développement et design /////////////////////////////
/////////Propriété privé de Layer Data - Rachid Tighremt//////////
////////Tous droits reservés ... interdit tout plagiat ///////////
////////    interdit tout usage sans autorisation     ////////////
///////---------------------------------------------//////////////
//////////////////////////////////////////////////////////////////
const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700;800&display=swap');
*{
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after{
  box-sizing: inherit;
}

html{
  box-sizing: border-box;
  font-size: 62.5%;

  @media only screen and (min-width: 1400px) {
    font-size: 75%;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 85%;
  }
  @media only screen and (min-width: 1900px) {
    font-size: 95%;
  }
  @media only screen and (min-width: 2200px) {
    font-size: 100%;
  }
}

body{
  font-family: 'Nunito', sans-serif;
  // font-family: 'kanit', sans-serif;
  font-size: 1.5rem;
  line-height: 1.3
  font-weight: 400;
}

//scrollbar
::-webkit-scrollbar{
    width: 18px;
}

::-webkit-scrollbar-thumb{
    background-color: #1fa8ff;
    height: 10rem;
    border-radius: 10px;
    margin-right: 2px;
    :hover{
        background-color: #1fa8ff;
    }
}
::-webkit-scrollbar-track{
    border: 1px solid #010606;
    background: #010606;
    box-shadow: inset 0 0 2px 2px rgba(0,0,0, .05)
}
`;

export default GlobalStyle;
