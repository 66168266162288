//////////////////////////////////////////////////////////////////
//////////////****AUTHOR : AGHILES AZZOUG -  *////////////////////
//////////////*****JUIN - AOUT 2021 */////////////////////////////
/////////////Développement et design /////////////////////////////
/////////Propriété privé de Layer Data - Rachid Tighremt//////////
////////Tous droits reservés ... interdit tout plagiat ///////////
////////    interdit tout usage sans autorisation     ////////////
///////---------------------------------------------//////////////
//////////////////////////////////////////////////////////////////
export const homeObjOne ={
  id: 'about',
  id2: 'vision',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Qui sommes-nous?',
  listeOne: "Nous sommes un cabinet de conseil expert sur les problématiques de Data Management. Fondé en 2020 par Rachid TIGHREMT, Layer Data est né du constat que le marché de la Gouvernance de données manquait de pure intégrateurs.",
  listeTop:"En 2 ans, nous avons recruté plus de 15 personnes et accompagné des clients dans l’Industrie, l’Assurance, la Banque et la Défense, dans l’opérationnalisation de leur Gouvernance Data.  Avec 6 partenaires technologiques et innovants, nous formons et certifions nos experts afin qu’ils répondent aux besoins d’intégration dans des environnements complexes avec nos convictions.",
  listeSeconde:"Nos performances nous ont permis de générer une croissance de 30% du chiffre d’affaires chaque année depuis notre création.",
  imgStart: true,
  img: require('../../assets/images/institution.gif').default,
  alt: 'presentation',
  dark: false,
  primary: true,
  darkText: true
};