import Icon1 from '../../assets/images/rachid.png'
import Icon2 from '../../assets/images/florient.png'
import Icon3 from '../../assets/images/yanis.png'
import { MDBContainer } from "mdbreact";
import { useTranslation } from 'react-i18next';
import 'mdbreact/dist/css/mdb.css';
//import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import {/*FaFacebook, FaInstagram, FaTwitter,*/ FaLinkedin} from 'react-icons/fa'
import {
    RessourcesContainer,
    RessourcesH1,
    RessourcesWrapper,
    RessourcesCard,
    //RessourcesCard1,
    RessourcesIcon,
    RessourcesH2,
    RessourcesPl,
    RessourcesP,
    RessourcesH3,
    SocialIconLink
} from './RessourcesStyled'
//////////////////////////////////////////////////////////////////
//////////////****AUTHOR : AGHILES AZZOUG -  *////////////////////
//////////////*****JUIN - AOUT 2021 */////////////////////////////
/////////////Développement et design /////////////////////////////
/////////Propriété privé de Layer Data - Rachid Tighremt//////////
////////Tous droits reservés ... interdit tout plagiat ///////////
////////    interdit tout usage sans autorisation     ////////////
///////---------------------------------------------//////////////
//////////////////////////////////////////////////////////////////
const Ressources = () => {
  const {t} = useTranslation();
  return (
    <div>
      <RessourcesContainer>
      <RessourcesH1 data-aos='fade-down' id="ressources">{t('rh.title')}</RessourcesH1>
       

      <MDBContainer fluid className="text-center text-md-left container-fluid container-lg g-4">

         <RessourcesWrapper>
          
          <RessourcesCard data-aos='fade-up' data-aos-delay="200">
            <RessourcesIcon src={Icon1} alt=''/>
            <RessourcesH3>Rachid TIGHREMT</RessourcesH3>
            <RessourcesP>{t('rh.poste.fondateur')}</RessourcesP>
            <RessourcesPl>{t('rh.poste.rachid')}</RessourcesPl>
                  <SocialIconLink href="https://www.linkedin.com/in/rtighremt/" target="_blank" noreferrer aria-label="Linkedin">
                    <FaLinkedin/>
                  </SocialIconLink>
          </RessourcesCard>
          
          <RessourcesCard data-aos='fade-up' data-aos-delay="200">
            <RessourcesIcon src={Icon3} alt=''/>
            <RessourcesH3>Yanis BOUCHAKEL</RessourcesH3>
            <RessourcesPl>{t('rh.poste.yanis')}</RessourcesPl>
                  <SocialIconLink href="https://www.linkedin.com/in/yanis-bouchakel-558a76120/" target="_blank" noreferrer aria-label="Linkedin">
                    <FaLinkedin/>
                  </SocialIconLink>
          </RessourcesCard>
          
          <RessourcesCard data-aos='fade-up' data-aos-delay="200">
            <RessourcesIcon src={Icon2}/>
            <RessourcesH2>Florian Blaise</RessourcesH2>
            <RessourcesP>{t('rh.poste.florian')}</RessourcesP>
                  <SocialIconLink href="https://www.linkedin.com/in/florian-blaise-layer-data/" target="_blank" noreferrer aria-label="Linkedin">
                    <FaLinkedin/>
                  </SocialIconLink>
          </RessourcesCard>
           {/* <RessourcesCard data-aos='fade-up' data-aos-delay="200">
            <RessourcesIcon src={Icon3}/>
            <RessourcesH2>Rayan Haroun</RessourcesH2>
            <RessourcesP>Data Ingénieur</RessourcesP>
                  <SocialIconLink href="https://www.linkedin.com/in/rayane-haroun-72587b203/" target="_blank" noreferrer aria-label="Linkedin">
                    <FaLinkedin/>
                  </SocialIconLink>
          </RessourcesCard> */}
          

        </RessourcesWrapper>
       </MDBContainer>
      </RessourcesContainer>
      
    </div>
  )
}

export default Ressources
