import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
//import { Button} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import Img1 from '../../assets/images/Logo_Maisons_du_Monde_FR.png';
//import { Link } from "react-router-dom";

const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "white", fontSize: "45px" }} />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "white", fontSize: "45px" }} />
    </div>
  );
};

const Testimonial = () => {
  const {t} = useTranslation();
  return (
    <div data-aos='fade-up' data-aos-delay="150"
      className="testimonial"
      style={{ display: "flex", justifyContent: "center", paddingTop: 80,paddingBottom: 80, backgroundColor:"#1fa8ff"  }}
    >
      <div style={{ width: "50%", textAlign: "center" }}>
        <h1 style={{ marginBottom: 20,color: "white" }}>{t('avis.title')}</h1>
        
        <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>

        {/*<Card
          img= 
          nom="" 
          poste="" 
          text=""  />*/}
        
        <Card 
          img={Img1}
          nom="Geoffroy G."
          poste=" Maison du Monde"
          text="Layer Data a déployé sa méthodologie de Change Management dédiée à la thématique de Gouvernance, et ce, pour nous aider au sein de Maisons du Monde à déployer la plateforme DataGalaxy dans les processus de l’entreprise et ainsi l’enrichir de manière pérenne ; l’exploiter dans de bonnes conditions pour aider les projets à avancer plus efficacement." 
        />
        
        </Slider>
      </div>
    </div>
  );
};

const Card = ({ img , text , nom , poste }) => {

  //const {t} = useTranslation();

  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", textAlign: "center", color: "white" }} >
        <img 
        alt={nom}
        src={img}
        style={{
          height: 120,
          width: 'auto',
         
          padding: 7,
          marginBottom: 20,
        }}
      />

      <strong>
      {text}
      </strong>
      <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: "black" }}>{nom} </span> ,
      {poste} 
      </p>
     {/**  
     <Link to="/temoignage">
      <Button variant="outlined" >{t('avis.bouton')}</Button>
      </Link> 
      */}
      
    </div>
    
  );
};

export default Testimonial;