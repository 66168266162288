import React from 'react';

//import Testimonial from '../components/Testimonial';
import Img1 from '../assets/images/Logo_Maisons_du_Monde_FR.png';
import { 
  MDBCol,
  MDBContainer,
  MDBRow
   } from 'mdb-react-ui-kit';

import Vershome from '../components/RetourAcceuil/VersAccueil';

function testimo(name ) {

  return (
    <div style={{ textAlign: 'justify' }} >
    <Vershome/>  
    <div style={{ padding: "30px 0px 30px 0px" }}>
  
   <MDBContainer>
    <MDBRow>
      <MDBCol size="12" md="5" lg="4" >
      <img 
        alt=''
        src={Img1}
        style={{
          maxHeight: 150,
          Height: 'auto',
          width: 'auto',
         
          padding: 10,  
        }} />

      </MDBCol>
      <MDBCol size="12" md="7" lg="8" >
      <h2><strong>Contexte</strong></h2>
  <p  >Maison du Monde est une entreprise française de mobilier et de décoration fondée en 1990. Elle conçoit, fabrique et vend des meubles et des objets de décoration d'inspiration ethnique, exotique et bohème. L'entreprise est présente dans plusieurs pays en Europe, en Amérique du Nord et au Moyen-Orient, avec plus de 360 magasins dans le monde entier. Elle emploie environ 8 000 personnes.</p>
  <p  >Dans le cadre de l’exécution de son programme de Gouvernance des Données, Maisons du Monde a fait appel à Layer Data pour la plateforme DataGalaxy, outil de Gouvernance et de catalogue des données.</p>
     </MDBCol>

    </MDBRow>
   </MDBContainer>
  
  </div>


  <div  style={{ backgroundColor: '#2196F3', paddingTop: "30px", paddingBottom: "30px", color: "white" }} >
  <MDBContainer>
    <MDBRow>
      
      <MDBCol size="12" md="8" lg="6" >

  <h1><strong>Objectif</strong></h1>
  <p>Notre feuille de route contient des objectifs de formation, d’initiation aux bonnes pratiques de la Data Gouvernance et du catalogage des équipes Data Office de Maisons du Monde.</p>
  <p>Nous devions également épauler Maisons du Monde dans une identification plus nette de ses données afin de maximiser leur valeur en utilisant les fonctionnalités avancées de DataGalaxy (Usages et Lineage).</p>
  <p>L’intégration du catalogue était également l’occasion d’unifier les approches de gestion de la donnée entre les équipes de Gouvernance et l’équipe Conformité (sujets RGPD)</p>

      </MDBCol>
      <MDBCol size="12" md="4" lg="6" >

      </MDBCol>
      </MDBRow>
      </MDBContainer>
 
  </div>


  <div style={{ padding: "30px 0px 30px 0px" }} >
    <MDBContainer>
 <h1><strong>Actions</strong></h1>

<ul>
  <li>Réalisation d’un audit des modèles de données de Maisons du Monde pour déterminer les niveaux applicables (domaines, sous domaines, concepts,…).</li>
  <li>Planification de l'implémentation : identification des ressources nécessaires et élaboration d’un calendrier pour l’implémentation</li>
  <li>Déploiement et intégration : Configuration des connecteurs, développement de nouveaux connecteurs, Intégration de DataGalaxy dans l'environnement de données existant de Maison du Monde et réalisation des tests de validation.</li>
  <li>Formation et assistance : formation des membres du Data Office de Maison du Monde sur la façon d'utiliser DataGalaxy, y compris les meilleures pratiques pour la collecte, l'analyse et la gestion des métadonnées.</li>
</ul>
</MDBContainer>
  </div>


<div style={{ backgroundColor: '#2196F3', padding: "30px", color: "white" }} >
  <MDBContainer>
<h1><strong>Résultat</strong></h1>
<p>Grâce à l'implémentation de l'outil DATA GALAXY, notre client a pu constater des résultats significatifs :</p>
<ul>
  <li>Les utilisateurs ont accès à des informations précises et à jour permettant ainsi une prise de décision rapide et efficace, sur un périmètre bien déterminé</li>
  <li>Le client a pu capitaliser sur les approches proposées par Layer Data autour de la modélisation pour continuer le travail avec ses équipes métier (identification, consolidation des modèles et des concepts) via DataGalaxy.</li>
  <li>Les visuels avancés (Lineage) permettent une analyse approfondie des données et facilite la compréhension des données les plus complexes.</li>
  <li>Layer Data a mis en place son approche et une implémentation de bout en bout pour gérer la conformité RGPD avec le soutient de la DPO Groupe.</li>
  <li>La DPO Groupe est en capacité de générer ses registres de traitement, ses analyses d’impacts, grâce à DataGalaxy, couplé avec des technologies ouvertes du marché, tel que proposé et prouvé par Layer Data</li>
  <li>Layer Data a déployé sa méthodologie de Change Management dédiée à la thématique de Gouvernance, et ce, pour aider Maisons du Monde a déployer la plateforme DataGalaxy dans les processus de l’entreprise et ainsi l’enrichir de manière pérenne ; l’exploiter aussi ; dans de bonnes conditions pour aider les projets à avancer plus efficacement.</li>
</ul>
<p>Enfin, Maisons du Monde, a acquis une autonomie, une méthodologie et des convictions sur la bonne façon de déployer la solution DataGalaxy dans son environnement et auprès de ses utilisateurs.</p>
</MDBContainer>
</div>

  {/*<Testimonial/> */}  
    </div>
    
  );
}

export default testimo;
