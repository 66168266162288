import React from "react";
import { MDBRow,MDBCol, MDBContainer, MDBFooter } from "mdbreact";
import 'mdbreact/dist/css/mdb.css';
import { FaLinkedin} from 'react-icons/fa'
import 'bootstrap-css-only/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";



const Footer = () => {


const {t} = useTranslation();

return (
    <MDBFooter color="blue">
      <MDBContainer fluid className="text-center text-md-left container-fluid container-lg g-4">
        <MDBRow className="py-5">
         
          <MDBCol md="4" className="text-sm-center text-md-start">
            <h1>{t('contacts.titre')}</h1>
           
              <h5 className="list-unstyled text-decoration-none pt-3">
              {t('contacts.adresse1')}<br />
              {t('contacts.adresse2')}
              </h5>
              <h5 className="list-unstyled">
              {t('contacts.fixe')}
              </h5>
              <h5 className="list-unstyled">
              {t('contacts.mobile')}
              </h5>
              <h5 className="list-unstyled" >
              {t('contacts.eMail')}
              </h5>
            
          </MDBCol>
          <MDBCol md="2" className="text-center p-2">
          {/*<h1>{t('contacts.socialmedia')}</h1>*/}
          <a href="https://www.linkedin.com/company/layer-data/" target="_blank" rel="noopener noreferrer"  style={{ textDecoration: "none"  }} >
          <FaLinkedin style={{ fontSize: '60px' }}/>
          </a>
          </MDBCol>
          <MDBCol md="6" className="text-sm-center text-md-end">
          
          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.188440965507!2d2.547302315673566!3d48.8355441792852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e60e0bc00a5457%3A0xb9699e12dcc16ab9!2s3%20Rue%20Sancho%20Panca%2C%2093160%20Noisy-le-Grand!5e0!3m2!1sfr!2sfr!4v1649330374312!5m2!1sfr!2sfr" width="60%" height="100%" style={{border:2}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" name="0021"/>
          </MDBCol>
        </MDBRow>
           
      </MDBContainer>
      <div className="footer-copyright text-center py-4" style={{backgroundColor : "#1F2041"}} >
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright -  Layer Data Consulting
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}






export default Footer;