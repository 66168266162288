//import Icon1 from '../../assets/images/semarchy.gif'
import Icon2 from '../../assets/images/datagalaxy.gif'
import Icon3 from '../../assets/images/informatica.gif'
import Icon4 from '../../assets/images/syndigo.gif'
import Icon5 from '../../assets/images/ataccama.gif'
import Icon6 from '../../assets/images/collibra.gif'
import Icon7 from '../../assets/images/denodo.png'
import {MDBContainer} from "mdbreact";
import { useTranslation } from 'react-i18next'
import 'mdbreact/dist/css/mdb.css';
//import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
//////////////////////////////////////////////////////////////////
//////////////****AUTHOR : AGHILES AZZOUG -  *////////////////////
//////////////*****JUIN - AOUT 2021 */////////////////////////////
/////////////Développement et design /////////////////////////////
/////////Propriété privé de Layer Data - Rachid Tighremt//////////
////////Tous droits reservés ... interdit tout plagiat ///////////
////////    interdit tout usage sans autorisation     ////////////
///////---------------------------------------------//////////////
//////////////////////////////////////////////////////////////////
import {
  PartenairesContainer,
  PartenairesH1,
  PartenairesWrapper,
  PartenairesCard,
  PartenairesIcon,
  PartenairesH2,
  PartenairesP,
  UrlCardLink
} from './PartenairesStyled'

const Partenaires = () => {
  const {t} = useTranslation();
  return (
    <div>
      <PartenairesContainer>
      <MDBContainer fluid className="text-center text-md-left container-fluid container-lg g-4">

        <PartenairesH1 data-aos='fade-down' id="partenaires">{t('partners.title')}</PartenairesH1>
        <PartenairesWrapper>
        
        <PartenairesCard data-aos='fade-up'data-aos-delay="200"> 
            <UrlCardLink  href="https://www.ataccama.com/" target="_blank">
            <PartenairesIcon src={Icon5}/>
            <PartenairesH2>Ataccama</PartenairesH2>
            <PartenairesP>{t('partners.details.ataccama')}</PartenairesP>
            </UrlCardLink>
        </PartenairesCard>

        <PartenairesCard data-aos='fade-up'data-aos-delay="200">
            <UrlCardLink  href="https://www.collibra.com/" target="_blank">
            <PartenairesIcon src={Icon6}/>
            <PartenairesH2>Collibra</PartenairesH2>
            <PartenairesP>{t('partners.details.collibra')}</PartenairesP>
            </UrlCardLink>
        </PartenairesCard>

        <PartenairesCard data-aos='fade-up' data-aos-delay="400">
            <UrlCardLink  href="https://www.denodo.com" target="_blank">
            <PartenairesIcon src={Icon7}/>
            <PartenairesH2>Denodo</PartenairesH2>
            <PartenairesP>{t('partners.details.denodo')}</PartenairesP>
            </UrlCardLink>
        </PartenairesCard>
        
        <PartenairesCard data-aos='fade-up'data-aos-delay="400">
            <UrlCardLink  href="https://www.datagalaxy.com/" target="_blank">
            <PartenairesIcon src={Icon2}/>
            <PartenairesH2>Data Galaxy</PartenairesH2>
            <PartenairesP>{t('partners.details.datagalaxy')}</PartenairesP>
            </UrlCardLink>
        </PartenairesCard>
                
          
        <PartenairesCard data-aos='fade-up'data-aos-delay="200">
            <UrlCardLink  href="https://www.informatica.com/" target="_blank">
            <PartenairesIcon src={Icon3}/>
            <PartenairesH2>Informatica</PartenairesH2>
            <PartenairesP>{t('partners.details.informatica')}</PartenairesP>
            </UrlCardLink>
        </PartenairesCard>
        
        

        <PartenairesCard data-aos='fade-up' data-aos-delay="400">
            <UrlCardLink  href="https://syndigo.com/" target="_blank">
            <PartenairesIcon src={Icon4}/>
            <PartenairesH2>Syndigo</PartenairesH2>
            <PartenairesP>{t('partners.details.syndigo')}</PartenairesP>
            </UrlCardLink>
        </PartenairesCard>


        </PartenairesWrapper>
        </MDBContainer>

      </PartenairesContainer>
      
    </div>
  )
}

export default Partenaires
