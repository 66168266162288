import React from 'react';
//import Navbar from '../components/Navbar';

//import { Link } from "react-router-dom";
//import Testimonial from '../components/Testimonial';
import { MDBCol, MDBContainer, MDBRow  } from 'mdb-react-ui-kit';
import InscriptionEvent from '../components/ContactForm/inscriptionsEvent';
import Vershome from '../components/RetourAcceuil/VersAccueil';
import Icon1 from '../assets/images/layer-data-consultant.gif';

function inscriptionEvent() {

  return (
    <div>
    <Vershome/>

   <MDBContainer style={{ paddingTop: "30px", paddingBottom: "30px" }} >
    <MDBRow>
      <h1 data-aos='fade-down'  data-aos-delay="100" > Text pour parler de l'event et appel a l'action </h1>
      <h3 data-aos='fade-down'  data-aos-delay="100" >Text pour parler de l'event et appel a l'inscription Text pour parler de l'event et appel a l'inscriptionText pour parler de l'event et appel a l'inscription Text pour parler de l'event et appel a l'inscriptionText pour parler de l'event et appel a l'inscription Text pour parler de l'event et appel a l'inscriptionText pour parler de l'event et appel a l'inscription Text pour parler de l'event et appel a l'inscription </h3>
      <strong data-aos='fade-down'  data-aos-delay="100" >Text pour parler de l'event et appel a l'inscription </strong>
   </MDBRow>
    <MDBRow>
      <MDBCol size="12" md="5" lg="4"  data-aos='fade-down'  data-aos-delay="100" >
      <img  src={Icon1} alt='Layer Data' width="100%" />
      </MDBCol>
      <MDBCol size="12" md="7" lg="8" >
      <InscriptionEvent />
      </MDBCol>

    </MDBRow>
   </MDBContainer>
     
    </div>
  );
}

export default inscriptionEvent;