
//////////////////////////////////////////////////////////////////
//////////////****AUTHOR : AGHILES AZZOUG -  *////////////////////
//////////////*****JUIN - AOUT 2021 */////////////////////////////
/////////////Développement et design /////////////////////////////
/////////Propriété privé de Layer Data - Rachid Tighremt//////////
////////Tous droits reservés ... interdit tout plagiat ///////////
////////    interdit tout usage sans autorisation     ////////////
///////---------------------------------------------//////////////
//////////////////////////////////////////////////////////////////

import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";

//images
import Icon1 from '../../assets/images/client/adecco.png';
import Icon2 from '../../assets/images/client/AFD.png';
import Icon3 from '../../assets/images/client/dgac.png';
import Icon4 from '../../assets/images/client/rothschild.png';
import Icon5 from '../../assets/images/client/thales.png';
import Icon6 from '../../assets/images/client/allianz.png';
import Icon7 from '../../assets/images/client/naval.png';
import Icon8 from '../../assets/images/client/s_g.png';
import Icon9 from '../../assets/images/client/limatech.png';
import Icon10 from '../../assets/images/client/edf.png';

//slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//package bootstrap
import {MDBContainer} from "mdbreact";
import 'mdbreact/dist/css/mdb.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
// import styled from 'styled-components';

import {
  CommercialContainer,
  CommercialH1,
  CommercialCard,
  CommercialIcon,
  UrlCardLink
} from './CommercialStyled';

const Commercial = () => {

    

  
 


    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
			autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 800,
          }
        }
       ]
    };
    
    const { t } = useTranslation();
    return (
      <CommercialContainer>
      <MDBContainer fluid data-aos='fade-up'  data-aos-delay="100" className="text-center text-md-left container-fluid container-lg g-4">

        <CommercialH1  id="clients">{t('trust')}</CommercialH1>
            <div className="container">
              <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
              <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
              {/* <style>{cssstyle}</style> */}
              {/* <h2>voici quelques clients</h2> */}
              <Slider data-aos='fade-up'  data-aos-delay="100" {...settings}>
              <CommercialCard >
                  <UrlCardLink  href="https://www.naval-group.com/fr" target="_blank">
                    <CommercialIcon src={Icon7}/>
                  </UrlCardLink>
                </CommercialCard>

                <CommercialCard> 
                  <UrlCardLink  href="https://www.thalesgroup.com/fr" target="_blank">
                    <CommercialIcon src={Icon5}/>
                  </UrlCardLink>
                </CommercialCard>

                <CommercialCard >
                  <UrlCardLink  href="https://www.rothschildandco.com/fr/" target="_blank">
                    <CommercialIcon src={Icon4}/>
                  </UrlCardLink>
                </CommercialCard>
                
                <CommercialCard>
                  <UrlCardLink  href="https://www.allianz.fr/" target="_blank">
                    <CommercialIcon src={Icon6}/>
                  </UrlCardLink>
                </CommercialCard>
              
                <CommercialCard> 
                  <UrlCardLink  href="https://www.thalesgroup.com/fr" target="_blank">
                    <CommercialIcon src={Icon5}/>
                  </UrlCardLink>
                </CommercialCard>
            
                <CommercialCard >
                  <UrlCardLink  href="https://www.groupe-adecco.fr/" target="_blank">
                    <CommercialIcon src={Icon1}/>
                  </UrlCardLink>
                </CommercialCard>
              
                <CommercialCard>
                  <UrlCardLink  href="https://www.ecologie.gouv.fr/direction-generale-laviation-civile-dgac" target="_blank">
                    <CommercialIcon src={Icon3}/>
                  </UrlCardLink>
                </CommercialCard>
            
                <CommercialCard>
                  <UrlCardLink  href="https://www.afd.fr/fr" target="_blank">
                    <CommercialIcon src={Icon2}/>
                  </UrlCardLink>
                </CommercialCard>

                <CommercialCard >
                  <UrlCardLink  href="https://www.edf.fr/" target="_blank">
                    <CommercialIcon src={Icon10}/>
                  </UrlCardLink>
                </CommercialCard>
              
                <CommercialCard>
                  <UrlCardLink  href="https://www.limatech.energy/" target="_blank">
                    <CommercialIcon src={Icon9}/>
                  </UrlCardLink>
                </CommercialCard>
            
                <CommercialCard>
                  <UrlCardLink  href="https://www.societegenerale.com/fr" target="_blank">
                    <CommercialIcon src={Icon8}/>
                  </UrlCardLink>
                </CommercialCard>

            </Slider>
          </div>
        </MDBContainer>

      </CommercialContainer>
    );
  }

export default  Commercial;