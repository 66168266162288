import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import "./contact.css"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
 
const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    poste: '',
    phone: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Vérification des champs obligatoires
    if (
      formData.firstName === '' ||
      formData.lastName === '' ||
      formData.email === '' ||
      formData.message === ''
    ) {
      toast.error('Veuillez remplir tous les champs obligatoires.');
      return;
    }
  
    // Vérification de l'email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      toast.error('Veuillez entrer une adresse email valide.');
      return;
    }
  
    // Vérification du numéro de téléphone
    const phoneRegex = /^[0-9()+-]*$/;
    if (!phoneRegex.test(formData.phone)) {
      toast.error('Veuillez entrer un numéro de téléphone valide (chiffres, parenthèses, signes plus et tirets autorisés).');
      return;
    }
  
    const formDataToSend = new FormData();
  
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
  
    const formDataEntries = formDataToSend.entries();
    let formDataString = '';
    for (const [name, value] of formDataEntries) {
      formDataString += `${name}: ${value}\n`;
    }
    
    console.log(formDataString);
    
    axios.post('./contact.php', formDataToSend)
      
      .then((response) => {
        //alert(response.message);
        toast.success(t('contactForm.Messages.Success'));
        
        setFormData({ firstName: '',  lastName: '', company: '', poste: '', phone: '', email: '', message: ''  });
      })
      
      .catch((error) => {
        //alert(error)
        toast.error(t('contactForm.Messages.Error'));
       } );


  };
  
   
  const {t} = useTranslation();
  //const notify = () => toast("Message sended !");

  return (
    <form onSubmit={handleSubmit} id='Contact' >
    <div className='sur2col'  data-aos='fade-up'  data-aos-delay="100"   >
      <input
        type="text"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        placeholder={t('contactForm.firstName')}
      />
    </div>
    <div className='sur2col' data-aos='fade-up'  data-aos-delay="100" >
      <input
        type="text"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        placeholder={t('contactForm.lastName')}
      />
    </div>
    <div className='sur2col' data-aos='fade-up'  data-aos-delay="100">
      <input
        type="text"
        name="company"
        value={formData.company}
        onChange={handleChange}
        placeholder={t('contactForm.company')}
      />
    </div>
    <div className='sur2col' data-aos='fade-up'  data-aos-delay="100">
      <input
        type="text"
        name="poste"
        value={formData.poste}
        onChange={handleChange}
        placeholder={t('contactForm.poste')}
      />
      </div>
    <div className='sur2col' data-aos='fade-up'  data-aos-delay="100">
      <input
        type="text"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        placeholder={t('contactForm.phone')}
      />
    </div>
    <div className='sur2col' data-aos='fade-up'  data-aos-delay="100">
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder={t('contactForm.email')}
      />
    </div>
    <div className='sur1col' data-aos='fade-up'  data-aos-delay="100" >
      <textarea
        name="message"
        value={formData.message}
        onChange={handleChange}
        placeholder={t('contactForm.message')}
      />
    </div>
    <button type="submit" data-aos='fade-up'  data-aos-delay="100" >{t('contactForm.submit')}</button>
    <ToastContainer />
  </form>
  
  );
};

export default ContactForm;

