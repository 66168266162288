import { MDBContainer } from "mdbreact";
import { useTranslation } from 'react-i18next';
import 'mdbreact/dist/css/mdb.css';
//import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Icon1 from '../../assets/images/governance.gif';
import Icon2 from '../../assets/images/MDM-Data.gif';
import Icon3 from '../../assets/images/data-management.gif';
import {
    OffresContainer,
    OffresH1,
    OffresWrapper,
    OffresCard,
    OffresIcon,
    OffresH2,
    OffresPl
} from './OffresStyled';

//////////////////////////////////////////////////////////////////
//////////////****AUTHOR : AGHILES AZZOUG -  *////////////////////
//////////////*****JUIN - AOUT 2021 */////////////////////////////
/////////////Développement et design /////////////////////////////
/////////Propriété privé de Layer Data - Rachid Tighremt//////////
////////Tous droits reservés ... interdit tout plagiat ///////////
////////    interdit tout usage sans autorisation     ////////////
///////---------------------------------------------//////////////
//////////////////////////////////////////////////////////////////

const Offres = () => {
  const { t } = useTranslation();
  return (
    <div>
      <OffresContainer >
      <MDBContainer fluid className="text-center text-md-left container-fluid container-lg g-4">
        <OffresH1 data-aos='fade-down' id="offres">{t('offres.title')}</OffresH1>
        <OffresWrapper>
          <OffresCard data-aos='fade-up' data-aos-delay="200">
            <OffresIcon src={Icon1}/>
            <OffresH2>{t('offres.details.titre1')}</OffresH2>
            <OffresPl><ul><li>{t('offres.details.detail1.l1')}</li><li>{t('offres.details.detail1.l2')}</li></ul></OffresPl>
          </OffresCard>

          <OffresCard data-aos='fade-up' data-aos-delay="200">
            <OffresIcon src={Icon2} alt=''/>
            <OffresH2>{t('offres.details.titre2')}</OffresH2>
            <OffresPl><li>{t('offres.details.detail2.l1')}</li><li>{t('offres.details.detail2.l2')}</li><li>{t('offres.details.detail2.l3')}</li></OffresPl>
          </OffresCard>
       
          <OffresCard data-aos='fade-up' data-aos-delay="200">
            <OffresIcon src={Icon3}/>
            <OffresH2>{t('offres.details.titre3')}</OffresH2>
            <OffresPl><li>{t('offres.details.detail3.l1')}</li><li>{t('offres.details.detail3.l2')}</li><li>{t('offres.details.detail3.l3')}</li></OffresPl>
          </OffresCard>
          
          

        </OffresWrapper>
        </MDBContainer>
      </OffresContainer>

      
    </div>
  )
}

export default Offres
