import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OffcanvasButton = ({ buttonText, offcanvasContent }) => {
  
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone:''
  });

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

    const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
        // Vérification des champs obligatoires
        if (
          formData.company === '' ||
          formData.email === '' ||
          formData.name === '' ||
          formData.phone === ''
        ) {

          toast.info(t('oc_hero.Message.ChampsVide'));
          
          return;
        }

    const formDataToSend = new FormData();
  
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
  
    const formDataEntries = formDataToSend.entries();
    let formDataString = '';
    for (const [name, value] of formDataEntries) {
      formDataString += `${name}: ${value}\n`;
    }
    
    console.log(formDataString);
    //toast(formDataString);

        axios.post('./lb.php', formDataToSend)
      
      .then((response) => {
        //alert(response);
        console.log(response.message);
        toast.success(t('oc_hero.Message.Success'));
        //toast("Message sended ! text verifiez votre boites ");
        setFormData({ name: '', company: '',  phone: '', email: '' });
      })
      
      .catch((error) => {
      console.log(error);
      toast.error(t('oc_hero.Message.Error'));
       } );
   
    setIsOffcanvasOpen(false);
  };

  const handleClose = () => {
    setFormData({
      Name: '',
      company: '',
      email: '',
      phone:''
    });
    setIsOffcanvasOpen(false);
  };

  const {t} = useTranslation();
  
  const offcanvasRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (offcanvasRef.current && !offcanvasRef.current.contains(event.target)) {
        setIsOffcanvasOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  




  return (
    <div>
      <button onClick={toggleOffcanvas} style={{ backgroundColor: '#1FA8FF', color: 'white', borderRadius: '20px', padding: '10px 20px' , margin: '20px' }} data-aos='fade-up' data-aos-delay="100" >{buttonText}</button>

      
      {isOffcanvasOpen && (
        <div ref={offcanvasRef} data-aos='fade-up' data-aos-delay="150" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#1FA8FF', padding: '2rem' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-right' }}>
            <button type="button" onClick={handleClose} style={{ backgroundColor: 'transparent', border: 'none', fontSize: '2rem' }}>
              x
            </button>
          </div>

          <MDBContainer style={{ padding: "10px 0px 10px 0px" }}>
          
            <h1 data-aos='fade-up'  data-aos-delay="100" >{t('oc_hero.paragraphe1')} </h1>
            <p data-aos='fade-up'  data-aos-delay="100" >{t('oc_hero.paragraphe2')}</p>
            <form data-aos='fade-up'  data-aos-delay="100" onSubmit={handleSubmit}>

              <MDBRow>
              <MDBCol>
              <input  type="text" id="name" name="name" placeholder={t('oc_hero.name')} value={formData.name} onChange={handleInputChange} />
              <input  type="text" id="company" name="company" placeholder={t('oc_hero.company')} value={formData.company} onChange={handleInputChange} />
              </MDBCol>
              <MDBCol>
              <input  type="email" id="email" name="email" placeholder={t('oc_hero.email')} value={formData.email} onChange={handleInputChange} />
              <input  type="text" id="phone" name="phone" placeholder={t('oc_hero.phone')} value={formData.phone} onChange={handleInputChange} />
              </MDBCol>
              
              <button type="submit" >{t('oc_hero.bouton')}</button>
              
              </MDBRow>
              
              
              
          </form>
          </MDBContainer>
          
        </div>
      )}
    </div>
  );
};

export default OffcanvasButton;
