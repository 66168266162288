import React from 'react';

import 'mdbreact/dist/css/mdb.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

import Icon1 from '../../assets/images/layer-data-consultant.gif';

import  './InfoStyles';

import {
  //InfoContainer,
  //InfoWrapper,
  //InfoRow,
  //Column1,
  //TextWrapper,
  TopLine,
  Heading,
  //SubTitle,
  //Column2,
  //ImgWrapper,
  //Img,
  SubText
} from '../Entreprise/EntrepriseStyles';

const Info = ({
  lightBg, 
  id, 
  imgStart, 
  //topLine, 
  //heading, 
  lightText,
  //description,
  //darkText,
  buttonLabel,
  img,
  alt,
  primary,
  //listeOne,
  //listeTop,
  //listeSeconde,
  //listeThird,
}) => {

   
const {t} = useTranslation();
  
return (

  <MDBContainer className='my-5'  >
      
      <MDBRow className='m-5 font-weight-bold' >
        <MDBCol  md={6} >
                  
                  <TopLine className='font-weight-bold' data-aos='fade-up'  data-aos-delay="100" >{t('work.title')} </TopLine>
                 <Heading lightText={lightText} data-aos='fade-up'  data-aos-delay="100" >{t('work.title-2')}</Heading>
               
                <div className='m-4'>
                  <SubText data-aos='fade-up'  data-aos-delay="100"  >• {t('work.paragraphe1')}</SubText>
                  <SubText data-aos='fade-up'  data-aos-delay="100" >• {t('work.paragraphe2')}</SubText>
                  <SubText data-aos='fade-up'  data-aos-delay="100" >• {t('work.paragraphe3')}</SubText> 
                </div>
                <SubText data-aos='fade-up'  data-aos-delay="100"><strong>{t('work.paragrapheCTA')}</strong></SubText>
        </MDBCol>

        <MDBCol md={6}  data-aos='fade-up'  data-aos-delay="100" >
              <img  src={Icon1} alt='Layer Data' width="100%" />
        </MDBCol>
      
      </MDBRow>
      </MDBContainer>
    
  )
}

export default Info;
