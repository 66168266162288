
import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'
//////////////////////////////////////////////////////////////////
//////////////****AUTHOR : AGHILES AZZOUG -  *////////////////////
//////////////*****JUIN - AOUT 2021 */////////////////////////////
/////////////Développement et design /////////////////////////////
/////////Propriété privé de Layer Data - Rachid Tighremt//////////
////////Tous droits reservés ... interdit tout plagiat ///////////
////////    interdit tout usage sans autorisation     ////////////
///////---------------------------------------------//////////////
//////////////////////////////////////////////////////////////////
export const Nav = styled.nav`
background: ${({scrollNav}) => (scrollNav? '#1fa8ff' : 'transparent')};
//${({scrollNav}) => (scrollNav? 'box-shadow: 1px 1px 1px 1px gray;':'')}
height: 80px;
display: flex;
justify-content: center;
align-items: center;
position: fixed;
width: 100%;
top: 0;
z-index: 10;

@media only screen and (max-width: 960px){
 transition: 0.8s all ease;
}
`;
export const NavContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
height: 90px;
z-index: 1;
width: 100%;
padding: 0 24px;
max-width: 1400px;

@media only screen and (max-width: 1200px){
 max-width: 1100px;
}
`;
export const NavLogo = styled(LinkR)`
text-decoration: none;
color: #fff;
display: flex;
align-items: center;
margin-left: 0px;
font-weight: 800;
//font-size: 2.6rem;
transition: all .3s ease;

&:hover{
  transform: scale(1.2) translateX(.5rem) translateY(.3rem);
  color: #2962f2;
}
`;

export const NavIcon = styled.div`
 display: none;


 @media only screen and (max-width: 1000px){
  display: block;
  font-size: 3rem;
  color: #2962f2;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 50%);
  
 }
`;

export const NavWrapper = styled.div`
display: flex;
justify-content: space-between;
padding-top:1.8rem;
@media only screen and (max-width: 1000px){
  display: flex;
  flex-direction: column;
}
`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
margin-right: -20px;
width: 75%;

@media only screen and (max-width: 1000px){
  width: 100%;
  height: 100vh;
  background: #1fa8ff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  left: ${({click}) => click ? '0': '-100%'};
  transition: all .5s ease;
}
`;
export const NavItem = styled.li`
height: 80px;
color: #fff;


`;
export const NavLink = styled(LinkS)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1.1rem;
height: 100%;
cursor: pointer;
transition: all .3s ease;
font-size: 2rem;


&:active{
  border-bottom: 3.2px solid #1fa8ff;
  underline:none;

}
&:hover,
&:active{
  color: #2962f2;
  underline:none;
}

@media only screen and (max-width: 1000px){
  font-size: 2.8rem;

  &:hover,
  &:active{
  transform: scale(1.06);
  border-bottom: 3px solid #2962f2;
  color : #2962f2
  underline:none;

  
  }
}

@media only screen and (max-width: 768px){
  font-size: 1.8rem;
  padding: 0 0 0 0;
  margin:0;
}
`;

export const NavBtn = styled.div`
display: flex;
align-items: center;
margin-left: 0px;
padding-top:1rem;


@media only screen and (max-width: 1000px){
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-top: 50px;
}
`;
export const NavBtnLink = styled(LinkR)`
text-decoration: none;
border-radius: 50px;
background: #2962f2;
white-space: nowrap;
padding: 10px 22px;
color: #fff;
outline: none;
border: none;
cursor: pointer;
transition: all .2s ease-in-out;
font-size: 1.5rem;
  underline:none;



&:hover{
  background: #fff;
  color: #2962f2;
  underline:none;

}

`;

export const NavbarIcon = styled.img`
//height: 5rem;
width: 190px;
display: flex;
align-items: center;
margin:0 30px 0 25px;


`;