
//import React, {useState/*,useEffect*/} from 'react';
import { useTranslation } from 'react-i18next';
import '../../locales/i18n';
import video from '../../assets/video/videohero.mp4'

//FOR VIDEO POP-UP
// import Icon from '../../assets/images/play-video.png'
 //import { ButtonForm } from "../ButtonElements";
// import ModalVideo from 'react-modal-video'
import OffcanvasButton from '../OffCanvass';

//FOR PUP-UP GUIDE
//import { Modal} from 'react-bootstrap'
//import 'mdbreact/dist/css/mdb.css';
//import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
//import styled from 'styled-components';

import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroTitle,
  HeroText,

  //FOR VIDEO POP-UP
  // HeroBtn,
  // ArrowForward,
  // ArrowRight

  //FOR GUIDE POP-UP
   //HeroBtn,
   //ArrowForward,
   //ArrowRight


  
} from './HeroStyles'


//NEWSLETTER GUIDE


 



const Hero = () => {

  const { t } = useTranslation();

       

  return (
    
      <HeroContainer id="home">
        <HeroBg>
          <VideoBg src={video} autoPlay muted playsInline loop/>
        </HeroBg>
        <HeroContent>
          <HeroTitle data-aos='fade-up'  data-aos-delay="100">{t('hero.title')}</HeroTitle>
          <HeroText data-aos='fade'  data-aos-delay="200">
          {t('hero.accroche')}
          </HeroText>

          

            <OffcanvasButton  buttonText={t('hero.bouton')} offcanvasContent={t('hero.bouton')}/>
          

        </HeroContent>

      </HeroContainer>
      
    
  )
}

export default Hero
