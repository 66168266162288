import React from 'react';
import { useTranslation } from 'react-i18next';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import 'mdbreact/dist/css/mdb.css';
//import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import img2 from '../../assets/images/presentation.gif';
import img1 from '../../assets/images/institution.gif';

import {
  InfoContainer,
  //InfoWrapper,
  //InfoRow,
  //Column1,
  //TextWrapper,
  TopLine,
  Heading,
  //SubTitle,
  //Column2,
  ImgWrapper,
  Img,
  SubText
} from './EntrepriseStyles';


const Entreprise = ({
  lightBg, 
  id,
  id2, 
  imgStart, 
  //topLine, 
  //heading, 
  //lightText,
  //description,
  //darkText,
  //img,
  alt,
  //listeOne,
  //listeTop,
  //listeSeconde

}) => {

const {t} = useTranslation();

  return (
    <div>
      <InfoContainer lightBg={lightBg}>
      <MDBContainer className="py-5">
        <MDBRow id="about">
            <MDBCol md='6'>
               <p>
                 <TopLine data-aos='fade-up'  data-aos-delay="100">{t('presentation.title')}</TopLine>
                 <SubText data-aos='fade-up'  data-aos-delay="300">{t('presentation.paragraphe1')}</SubText>
                 <SubText data-aos='fade-up'  data-aos-delay="300">{t('presentation.paragraphe2')}</SubText>
                 <SubText data-aos='fade-up'  data-aos-delay="300">{t('presentation.paragraphe3')}</SubText>                 
              </p>
            </MDBCol>
            <MDBCol md='6'>
            <ImgWrapper className="section d-flex align-items-center" data-aos='fade-up'  data-aos-delay="100">
            <Img src={img1} alt={alt}/>
            </ImgWrapper>
            </MDBCol>
          </MDBRow>
        <MDBRow center>
            <MDBCol md='6'>
               
              <ImgWrapper className="section d-flex align-items-center" data-aos='fade-up'  data-aos-delay="100">
            <Img src={img2} alt={alt}/>
            </ImgWrapper>
            </MDBCol>
            <MDBCol md='6' id="vision">
            <p>
                 <TopLine data-aos='fade-up'  data-aos-delay="100">{t('vision.title')}</TopLine>
                 <Heading data-aos='fade-up'  data-aos-delay="300">{t('vision.title-2')}</Heading>
                 <SubText data-aos='fade-up'  data-aos-delay="300">{t('vision.paragraphe1')}</SubText>
                 <SubText data-aos='fade-up'  data-aos-delay="300">{t('vision.paragraphe2')}</SubText>
                   
              </p>
            </MDBCol>
          </MDBRow>

        </MDBContainer>
      </InfoContainer>
    </div>
  )
}

export default Entreprise
