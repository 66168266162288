//////////////////////////////////////////////////////////////////
//////////////****AUTHOR : AGHILES AZZOUG -  *////////////////////
//////////////*****JUIN - AOUT 2021 */////////////////////////////
/////////////Développement et design /////////////////////////////
/////////Propriété privé de Layer Data - Rachid Tighremt//////////
////////Tous droits reservés ... interdit tout plagiat ///////////
////////    interdit tout usage sans autorisation     ////////////
///////---------------------------------------------//////////////
//////////////////////////////////////////////////////////////////

export const homeObjTwo ={
  id: 'vision', 
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Notre Vision',
  heading: 'La construction effective de la Data Gouvernance dans les organisations suppose 2 niveaux d’expertise :', 
  listeOne: "• Un niveau Conseil pour l’organisation de votre Data Office (Rôles, périmètres, Approches méthodologiques, Processus de gestion de la Data, …)",
  listeTop:'• Un niveau applicatif autour des plateformes de Gouvernance de métadonnées (Catalogues), de données de référence (MDM) et la gestion de la Qualité des données (DQ).',
  buttonLabel: 'En savoir plus ',
  imgStart: false,
  img: require('../../assets/images/presentation.gif').default,
  alt: 'presentation',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjFoor ={
  id: 'signup', 
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Travaillons ensemble',
  heading: 'DISCUTONS DE VOS PROBLÉMATIQUES ET DE VOS BESOINS:', 
  listeOne: "• Vous voulez opérationnaliser votre gouvernance des données.",
  listeTop:'• Vous avez mis en place un catalogue de données et souhaitez vous projeter sur la phase Run.',
  listeSeconde:'• Vous avez du mal à gérer la connectivité de votre catalogue Data.',
  listeThird:'Prenez contact avec un conseiller Layer Data pour avoir plus d’informations.',
  buttonLabel: 'Nous contacter',
  imgStart: false,
  img: require('../../assets/images/layer-data-consultant.gif').default,
  alt: 'benefites',
  dark: false,
  primary: true,
  darkText: true
};