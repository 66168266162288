import Icon1 from '../../assets/images/accompagnement.png'
import Icon2 from '../../assets/images/POC.png'
import Icon3 from '../../assets/images/pilote.png'
import Icon4 from '../../assets/images/projet.png'
import {MDBContainer } from "mdbreact";
import { useTranslation } from 'react-i18next'; 
import 'mdbreact/dist/css/mdb.css';
//import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  //ServicesCard1,
  ServicesCard11,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesStyled'
//////////////////////////////////////////////////////////////////
//////////////****AUTHOR : AGHILES AZZOUG -  *////////////////////
//////////////*****JUIN - AOUT 2021 */////////////////////////////
/////////////Développement et design /////////////////////////////
/////////Propriété privé de Layer Data - Rachid Tighremt//////////
////////Tous droits reservés ... interdit tout plagiat ///////////
////////    interdit tout usage sans autorisation     ////////////
///////---------------------------------------------//////////////
//////////////////////////////////////////////////////////////////
const Services = () => {
  const { t } = useTranslation();
  return (
    <div>
      
      <ServicesContainer>
      <MDBContainer fluid className="text-center text-md-left container-fluid container-lg g-4">

        <ServicesH1 data-aos='fade-down' id="services">{t('services.title')}</ServicesH1>
        <ServicesWrapper>
          <ServicesCard11 data-aos='fade-up' data-aos-delay="200">
            <ServicesIcon src={Icon1} alt=''/>
            <ServicesH2> {t('services.details.titre1')}</ServicesH2>
            <ServicesP>{t('services.details.detail1')}</ServicesP>

            
          </ServicesCard11>
          <ServicesCard data-aos='fade-up' data-aos-delay="200">
            <ServicesIcon src={Icon2}/>
            <ServicesH2>{t('services.details.titre2')}</ServicesH2>
            <ServicesP>{t('services.details.detail2')}</ServicesP>

                  
          </ServicesCard>
          <ServicesCard data-aos='fade-up' data-aos-delay="200">
            <ServicesIcon src={Icon3}/>
            <ServicesH2>{t('services.details.titre3')}</ServicesH2>
            <ServicesP>{t('services.details.detail3')}</ServicesP>

                
          </ServicesCard>
           <ServicesCard data-aos='fade-up' data-aos-delay="200">
            <ServicesIcon src={Icon4}/>
            <ServicesH2>{t('services.details.titre4')}</ServicesH2>
            <ServicesP>{t('services.details.detail4')}</ServicesP>

               
          </ServicesCard>
          

        </ServicesWrapper>
       </MDBContainer>
      </ServicesContainer>
    </div>
  )
}

export default Services
