import React from "react";
import { useEffect } from "react";
//import { useTranslation } from 'react-i18next';
//import i18n from './locales/i18n';
import GlobalStyles from "./GlobalStyles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import Home from "./pages/index";
import tem from "./pages/detailsTem";
import event from "./pages/inscriptionEvent";

import Fouter from "./components/Footer";

import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import '../node_modules/react-modal-video/css/modal-video.css';


function App() {
  
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <Router>
      <GlobalStyles/>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/temoignage" component={tem} />
        <Route exact path="/InscriptionEvent" component={event} />
      </Switch>
      <Fouter/>
    </Router>
  );

}

export default App;
